import { authorisationGuard } from '@shared/guards';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const dataSetsTextRoutes: InlightsRoute[] = [
	{
		title: 'Text Data Sets',
		icon: 'dataset',
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
		// This must be changed back to data-sets-text
			requiredClaims: [{ name: 'image-definitions', value: ['read'] }],
		},
		path: 'data-sets-text', canActivate: [authorisationGuard], loadComponent: () => import('./pages/data-sets-text-index/data-sets-text-index.component').then(m => m.DataSetsTextIndexComponent),
		children: [{ title: 'New Text Data Set',
			path: 'details/new', loadComponent: () => import('./pages/data-sets-text-index/data-set-text-create/data-set-text-create.component').then(m => m.DataSetTextCreateComponent),
		},
		{
			title: 'Text Data Set Details',
			path: 'details/:id', loadComponent: () => import('./pages/data-sets-text-index/data-sets-text-details/data-sets-text-details.component').then(m => m.DataSetsTextDetailsComponent),
			children: [
				{ path: 'file-versions/:versionId/validation', loadComponent: () => import('./pages/data-sets-text-index/data-sets-text-details/data-set-text-file-validation/data-set-text-file-validation.component').then(m => m.DataSetTextFileValidationComponent) }
			]
		}
		]
	},
];
